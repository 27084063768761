import { CATALOG_URL } from "@/assets/js/constantUtils";
let axios = require('axios');

export function searchProducts(keyterm, callback) {

    axios.get(CATALOG_URL.SEARCH_PRODUCT().format({keyterm}))
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}

export function getProductByEan(ean, callback) {
    axios.get(CATALOG_URL.GET_PRODCUT_BY_EAN().format({ean}))
        .then(response => {
            if(response.status == 200){
                if(!response.data.error && response.data != ""){
                    callback(response.data);
                }else{
                    callback({error : true});
                }
            }else{
                callback({error : true});
            }
        })
}
export function getCrossSellProducts(productId, callback){
    axios.get(CATALOG_URL.GET_CROSS_SELL().format({productId}))
        .then(response => {
            if(response.status == 200){
                callback(response.data);
            }
        })
}