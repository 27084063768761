<template>
    <div class="ticket">
        <Steps :steps="processSteps" :active="3" />
        <div class="ticket-body">
            <div class="order-timeline">
                <div class="divider-vertical"></div>
                <div class="timeline-elmt">
                    <div :class="{'timeline-icon': true, 'active': status.confirmed}">
                        <span v-if="status.confirmed" class="icon-check"></span>
                        <span v-else class="icon-three-dots">...</span>
                    </div>
                    <span class="timeline-label">{{$t("orderConfirmed")}}</span>
                </div>
                <div class="timeline-elmt">
                    <div :class="{'timeline-icon': true, 'active': status.inPreparation}">
                        <span v-if="status.inPreparation" class="icon-check"></span>
                        <span v-else class="icon-three-dots">...</span>
                    </div>
                    <span class="timeline-label">{{$t("orderInPreparation")}}</span>
                </div>
                <div class="timeline-elmt">
                    <div :class="{'timeline-icon': true, 'active': status.prepared}">
                        <span v-if="status.prepared" class="icon-check"></span>
                        <span v-else class="icon-three-dots">...</span>
                    </div>
                    <span class="timeline-label">{{$t("orderReady")}}</span>
                </div>
            </div>


            <div v-if="status.prepared" class="my-3 d-flex flex-column align-items-center justify-content-center">
                <p v-html="$t('pickOrderMessage')"></p>
                <button :class="{'footer-btn mt-0':true, 'translucide': false}" @click="$router.push('/scan?lockerScan=true')">{{$t("pickOrder")}}</button>
            </div>


            <h4>{{$t("whereToPickUp")}}</h4>

            <p v-html="$t('pickupAddress')"></p>
            <p v-html="$t('pickupHours')"></p>

            <div class="maps" :style="{backgroundImage: `url('${require('@/assets/img/maps.jpg')}')`}" ></div>
        </div>
        <div class="ticket-footer">
            <button :class="{'footer-btn':true, 'translucide': false}" @click="showFaceRegisterDialog = true" v-if="faceSignature == null">{{$t("registerFaceRecognition")}}</button>
            <button :class="{'footer-btn':true, 'translucide': false}" >{{$t("contactResponsable")}}</button>
        </div>

      <b-modal id="faceRegister" v-model="showFaceRegisterDialog" centered :title="$t('faceRegister')" hide-footer>
        <div v-if="faceSignature == null">
          <p style="text-align: center">{{$t('faceRegisterSubtitle')}}</p>
          <div class="webcam" ref="webcam">
            <div class="snapshot-btn" @click="takePicture">
              <i class="mdi mdi-camera"></i>
            </div>
          </div>
          <span class="face-register-error" v-if="errorFaceRegister">{{$t('faceRegisterError')}}</span>
        </div>
        <div v-else>
          <p style="text-align: center">{{$t('faceRegisterSuccess')}}</p>
          <img src="@/assets/img/ic_check.png" class="img_success">
        </div>
      </b-modal>

    </div>
</template>

<script>
    import {isNotNull, sendMail, sendSMS} from "@/assets/js/Utils"
    import Steps from "@/components/Food/Steps";
    import ProductBloc from "@/components/Food/ProductBloc";

    var qrcodeGenerator = require('qrcode-generator')
    var Utils = require("@/assets/js/Utils");
    var Basket = require("@/assets/js/Food/Basket");
    var Customer = require("@/assets/js/Customer");
    var Camera = require("@/assets/js/Camera")
    var FaceRecognition = require("@/assets/js/FaceRecognition")
    var Loyalty = require("@/assets/js/Loyalty");
    const moment = require("moment")

    export default {
        name: 'Ticket',
        components: {Steps, ProductBloc},
        props: {
            'paymentSuccess' : String
        },
        data(){
            return{
                customerBasket: null,
                img_url : null,
                ticket : {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                qrCodeImg :'',
                fromRoute : null,
                modalShow: false,
                time: "",
                clockParams: {
                    month: "long",
                    weekday: "long",
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                },
                keyword: sessionStorage.getItem("DKC_food_keyword"),
                processSteps: Utils.getSteps(this.$t),
                storeName: localStorage.getItem("DKC_food_city") || "Seclin",
                brandName: localStorage.getItem("DKC_food_brand") || this.$t("yourStore"),
                showMailModal: false,
                // email: localStorage.getItem("IEC_EMAIL") || "",
                email: "",
                phoneNumber: "",
                selectedSendMethod: 'mail',
                basketIsValidated: false,
                base: localStorage.getItem("DKC_base"),
                tel: JSON.parse(localStorage.getItem("DKC_customerPhoneNumber")) || "",
                page: {
                    titleAtRight: true,
                    title: this.$t("pages.ticket.title"),
                    description: this.$t("pages.ticket.description"),
                    showBackBtn: false,
                    showFilterBtn: false,
                    showBasket: false,
                    showSearch: false,
                    showScan:false
                },
                showFaceRegisterDialog: false,
                videoDimensions: {
                  height: 0,
                  width: 0
                },
                video: null,
                faceSignature: null,
                errorFaceRegister: false,
                status: {
                    confirmed: true,
                    inPreparation: false,
                    prepared: false,
                    finish: false
                }
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            if(this.tel != ""){
                this.phoneNumber = this.tel.phoneNumber
            }
            this.decodeSubstate(localStorage.getItem("IEC_SUBSTATE"))
            this.$parent.checkDemonstrationLogo();
            document.addEventListener("demoFetchedEvent", e =>{
                this.storeName = localStorage.getItem("DKC_food_city") || "Seclin"
                this.brandName = localStorage.getItem("DKC_food_brand") || this.$t("yourStore")
            }, false)
            let lang = localStorage.getItem("IEC_lang") || this.$route.query.lang || "en"
            this.time = (new Date()).toLocaleString(lang, this.clockParams)
            setInterval(() => {this.time = (new Date()).toLocaleString(lang, this.clockParams)}, 999);
            var config = Utils.getConfig();
            this.img_url = config.url_img;
            var qr = qrcodeGenerator(0, 'L');

            if(isNotNull(this.$route.query.ticketId)){
                var qrData = this.$route.query.ticketId;
                var demoId = this.$route.query.demoId;
                var univers = this.$route.query.univers;
                this.$i18n.locale = lang;
                Basket.getTicket(this.$route.query.ticketId, ticket => {
                    this.ticket = ticket;
                    this.page.title = this.$t("pages.ticket.title", {orderNumber: this.ticket.orderNumber})
                    this.updatePageInfo()
                    this.ticket.basketProducts = ticket.basketProducts;
                    qrData += "__" + ticket.customer.firstName;
                    qr.addData(qrData);
                    qr.make();
                    this.qrCodeImg = qr.createDataURL(10, 0);
                })
            }else{
                var firstname = localStorage.getItem("DKC_firstname");
                let selectedSlot = JSON.parse(localStorage.getItem("IEC_SELECTED_SLOT"))
                let lang = localStorage.getItem("IEC_lang")
                let comment = ""
                if(selectedSlot){
                    moment.locale(lang)
                    comment = moment(selectedSlot.selectedDay.day.date).format("LL") + "<br/>" + this.$t("at") + " " + selectedSlot.selectedDay.availableSlots[selectedSlot.selectedSlotId].slot
                }
                var self = this;
                setInterval(() => {this.time = (new Date()).toLocaleString(lang, this.clockParams)}, 999);
                let customerBasket = this.$parent.basket;
                if(customerBasket.basketProducts.length > 0){
                    Basket.validBasket(comment, function(res) {
                        localStorage.setItem("DKC_basket", JSON.stringify(res));
                        self.$parent.basketChange();
                        var loyaltyUse = localStorage.getItem("DKC_loyaltyToUse");
                        var ticketAmount = localStorage.getItem("DKC_ticketAmount");
                        if(isNotNull(loyaltyUse) && isNotNull(ticketAmount) && isNotNull(self.$parent.customer) && isNotNull(self.$parent.customer.loyalty)){
                            var newNbPoints = self.$parent.customer.loyalty.loyaltyPoint - loyaltyUse;
                            var winPoints = (ticketAmount * Utils.getConfig().loyaltyPourcent) / 100
                            newNbPoints += winPoints;
                            newNbPoints = newNbPoints.toFixed(2);
                            if(newNbPoints > 0){
                                Customer.updateCustomerLoyaltyPoints(newNbPoints);
                            }else{
                                Customer.updateCustomerLoyaltyPoints(0);
                            }
                            localStorage.removeItem("DKC_loyaltyToUse");
                        }

                        let encodedTicketId = localStorage.getItem("encodedTicketId");
                        if(isNotNull(encodedTicketId) && isNotNull(firstname)){
                            qr.addData(encodedTicketId + "__" + firstname);
                            qr.make();
                            self.qrCodeImg = qr.createDataURL(10, 0);
                        }
                        let mqttTopic = self.$mqtt.topics.DEFAULT_TOPIC + self.$mqtt.topics.SEND_SUBSTATE
                        self.$mqtt.initMqttClient(() => {
                            console.log(encodedTicketId)
                            localStorage.removeItem("IEC_SUBSTATE")
                            self.decodeSubstate()
                            let message = {
                                subState: "NEW",
                                basketComplexId: encodedTicketId
                            }
                            Basket.changeSubState(encodedTicketId, "NEW", () => {
                                self.$mqtt.publishMqtt(mqttTopic, JSON.stringify(message), function () {});
                                self.$mqtt.subscribeMqtt(mqttTopic, () => {
                                    self.$mqtt.listenMqttMessage(mqttTopic, message => {
                                        let decodedMessage = JSON.parse(new TextDecoder().decode(message))
                                        console.log("decodedMessage : " + decodedMessage)
                                        localStorage.setItem("IEC_SUBSTATE", decodedMessage.subState)
                                        if(decodedMessage && decodedMessage.basketComplexId && decodedMessage.basketComplexId == encodedTicketId){
                                                self.decodeSubstate(decodedMessage.subState)
                                        }
                                    })
                                })

                            })
                        })
                        self.basketIsValidated = true
                        self.sendTicket()

                        self.updateLoyalty()

                    });
                }else{
                    let encodedTicketId = localStorage.getItem("encodedTicketId");
                    if(isNotNull(encodedTicketId)){
                      Basket.getTicket(encodedTicketId, ticket => {
                          this.ticket = ticket;
                          this.page.title = this.$t("pages.ticket.title", {orderNumber: this.ticket.orderNumber})
                          this.updatePageInfo()
                          this.ticket.basketProducts = ticket.basketProducts;
                          this.decodeSubstate(this.ticket.subState)

                          localStorage.setItem("IEC_SUBSTATE", this.ticket.subState)
                          let mqttTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.SEND_SUBSTATE
                          this.$mqtt.initMqttClient(() => {
                              this.$mqtt.subscribeMqtt(mqttTopic, () => {
                                  this.$mqtt.listenMqttMessage(mqttTopic, message => {
                                      let decodedMessage = JSON.parse(new TextDecoder().decode(message))
                                      console.log("decodedMessage : " + decodedMessage)
                                      localStorage.setItem("IEC_SUBSTATE", decodedMessage.subState)
                                      if(decodedMessage && decodedMessage.basketComplexId && decodedMessage.basketComplexId == encodedTicketId){
                                              this.decodeSubstate(decodedMessage.subState)
                                      }
                                  })
                              })
                          })
                      })
                    }
                }
                this.ticket = JSON.parse(localStorage.getItem("IEC_ticket"));
                if(this.ticket){
                    this.page.title = this.$t("pages.ticket.title", {orderNumber: this.ticket.orderNumber})
                    this.updatePageInfo()
                }
                if(!Utils.getLocalSatisfaction("FOOD")){
                    this.modalShow = true;
                }
            }


          /*this.faceSignature = localStorage.getItem("IEC_FACE_SIGNATURE")
          if(isNotNull(this.faceSignature)){
            FaceRecognition.addSignatureToCorpus(localStorage.getItem("IEC_FACE_SIGNATURE")).then(({data, status}) => {
              if(status === 200){
                console.log(data)
              }
            });
          }*/
        },
      mounted() {
        this.$root.$on("bv::modal::shown", (event, modalId) => {
          if(modalId === "faceRegister"){
            this.initCamera()
          }
        })
        this.$root.$on("bv::modal::hidden", (event, modalId) => {
          if(modalId === "faceRegister"){
            this.showFaceRegisterDialog = false
          }
        })
      },
      methods:{
            updateLoyalty(){
              let jsonInfos = {};

              let hash = this.$route.query.h
              if(isNotNull(hash))
                jsonInfos.hashPan = hash

              let token = localStorage.getItem("IEC_TOKEN")
              if(isNotNull(token))
                jsonInfos.token = localStorage.getItem("IEC_TOKEN")

              let complexId = localStorage.getItem("IEC_COMPLEX_ID")
              if(isNotNull(complexId))
                jsonInfos.complexId = localStorage.getItem("IEC_COMPLEX_ID")


              Loyalty.updateLoyalty(jsonInfos, (res) => {
                console.log(res)
              });
            },
            handleBack (fallback) {
                if (!this.fromRoute) {
                    this.$router.push(fallback);
                } else {
                    window.location.replace(window.location.origin + this.fromRoute.path);
                }

            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            redirect(){
                window.location.replace(window.location.origin + '/');
            },
            getLang(){
                return this.$root.$i18n.locale;
            },
            showSendModal(){
                if(this.basketIsValidated){
                    this.showMailModal = true;
                }
            },
            sendTicket(){
                let loadedDemo = sessionStorage.getItem("IEC_loadedDemo")
                let email = localStorage.getItem("IEC_EMAIL")
                if(this.basketIsValidated){
                    let encodedTicketId = localStorage.getItem("encodedTicketId");
                    const ticketUrl = window.location.origin + "/ticket?ticketId=" + encodedTicketId;
                    if(loadedDemo){
                        ticketUrl += `&demoId=${loadedDemo}`
                    }
                    if(email){
                        sendMail(email, ticketUrl, encodedTicketId, this.$i18n.locale);
                    }
                    if(this.tel.isValid){
                        localStorage.setItem("DKC_customerPhoneNumber", JSON.stringify(this.tel))
                        const ticketUrl = "https://awl.li/scan-pay-ticket" + `?` +  "ticketId=" + encodedTicketId
                        if(loadedDemo){
                            ticketUrl += `&demoId=${loadedDemo}`
                        }
                        sendSMS(this.$t("ticketSms") + ticketUrl, this.tel.formattedNumber.replace("+", "00"))
                    }
                }
                this.showMailModal = false
          },
          takePicture() {
            this.errorFaceRegister = false
            Camera.pause()
            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            context.canvas.width = this.videoDimensions.width
            context.canvas.height = this.videoDimensions.height
            context.drawImage(this.video, 0, 0)

            let base64Image = canvas.toDataURL("image/jpeg")
            FaceRecognition.getSignatureFromBase64(base64Image).then(({data, status}) => {
              if(status === 200){
                console.log(data)
                console.log(data.signature)
                if(isNotNull(data.signature)){
                  localStorage.setItem("IEC_FACE_SIGNATURE", data.signature)
                  this.faceSignature = data.signature
                  this.addSignatureToCorpus()
                }else{
                  this.errorFaceRegister = true
                  Camera.play()
                }
              }
            });
          },
          addSignatureToCorpus(){
                if(isNotNull(localStorage.getItem("IEC_FACE_SIGNATURE"))){
                  FaceRecognition.addSignatureToCorpus(localStorage.getItem("IEC_FACE_SIGNATURE")).then(({data, status}) => {
                    if(status === 200){
                      console.log(data)
                    }
                  });
                }
          },
          cameraInitSuccess(dimensions, videoElmt){
            this.videoDimensions.height = dimensions.height
            this.videoDimensions.width = dimensions.width
            this.video = videoElmt

          },
          cameraInitError(){
            console.log("Error init camera")
          },
          onResizeCallback(){
            // this.$refs.webcam.setAttribute("style", `width: ${window.innerWidth}px; height:${window.innerHeight - 150}px`);
            // this.$refs.webcamWrapper.setAttribute("style", `background: black; width: ${window.innerWidth}px; height:${window.innerHeight}px`);
          },
          initCamera(){
             this.$refs.webcam.setAttribute("style", `width: 100%`);
            // this.$refs.webcamWrapper.setAttribute("style", `background: black; width: ${window.innerWidth}px; height:${window.innerHeight}px`);

            let constraints = {
              audio: false,
              video: {
                width: {
                  ideal: 640
                },
                height: {
                  ideal: 480
                },
                facingMode: {
                  ideal: "user"
                }
              }
            }
            Camera.init(this.$refs.webcam, constraints, this.cameraInitSuccess, this.cameraInitError, this.onResizeCallback)
          },
          decodeSubstate(subState){
            switch(subState){
                case "IN_PREPARATION":
                    this.status.inPreparation = true
                    break;
                case "PREPARED":
                    this.status.inPreparation = true
                    this.status.prepared = true
                    break;
                case "FINISH":
                    this.status.inPreparation = true
                    this.status.prepared = true
                    this.status.finish = true
                    break;
                default:
                    this.status.inPreparation = false
                    this.status.prepared = false
                    this.status.finish = false
                    break;
            }
          }
        },
        computed:{
            nbrProducts : function() {
                var count = 0;
                if(this.ticket.basketProducts){
                    this.ticket.basketProducts.forEach(function(cartProduct) {
                        count+=cartProduct.qty;
                    });
                    return count
                }
            },
            sortedCartProducts(){
                return this.ticket.basketProducts.sort((p1, p2) => p2.product.price - p1.product.price)
            },
            formattedTime(){
                let time = this.time.split(" ")
                let result = ""
                return time.map(elmt => {return elmt.charAt(0).toUpperCase() + elmt.slice(1)})
                    .join(" ")
            }
        },
      beforeDestroy(){
        console.log("Before destroy")
        Camera.stop()
        this.$mqtt.endClient()
      },
    }
</script>
<style scoped>
    .ticket h4{
        color: rgb(var(--hot-food-color));
    }
    .ticket-header{
        padding: 16px;
        background: rgb(var(--hot-food-color));
        color: white;
        border-bottom-right-radius: 25px;
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
    }
    .ticket-header .title{
        display: flex;
        justify-content: space-between;
    }
    .ticket-header h3{
        margin: 0 8px;
        font-weight: bold;
    }
    .ticket-header p{
        margin: 16px 0;
    }
    .ticket-header span{
        font-size: 1.6rem;
    }
    .ticket-header .filter-icon{
        margin-left: auto;
    }
    .order-timeline{
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
    }
    .order-timeline .divider-vertical{
        height: 70%;
        width: 2px;
        background: #46b8a6;
        position: absolute;
        left: 27px;
        z-index: -1;
    }
    .order-timeline .timeline-elmt{
        display: flex;
        align-items: center;
        margin: 8px 0;
    }
    .timeline-icon{
        margin: 0 16px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: white;
        color: #46b8a6;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid;
    }
    .timeline-icon.active{
        color: white;
        background: #46b8a6;
        border: none;
    }
    .maps{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        height: 150px;
        border: solid 2px #46b8a6;
    }

    .ticket-footer{
        width: 100%;
        background: #ffffff75;
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(2px);
    }
    .icon-three-dots{
        display: table-cell;
        vertical-align: middle;
        height: 28px;
    }
    .ticket-body{
        padding: 0 32px;
        overflow-y: scroll;
        padding-bottom: 128px;
    }
    .snapshot-btn{
      border-radius: 25px;
      background: #46b8a6;
      position: absolute;
      bottom: 8px;
      padding: 0px 32px;
      font-size: 2rem;
      color: white;
      box-shadow: 1px 1px 11px 5px #0000006b;
    }
    .webcam{
      position: relative;
      display: flex;
      justify-content: center;
    }
    .img_success{
      width: 50%;
      display: block;
      margin: auto;
    }
    .face-register-error{
      font-style: italic;
      font-size: .85rem;
      color: #dc3545;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
</style>
