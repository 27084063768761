import {getConfig, isNotNull} from "./Utils";
var axios = require("axios")

import {CUSTOMER_URL} from "@/assets/js/constantUtils";

var app = getConfig();

export  function registerCustomer(customerInfos, callback){
    let anonymToken = localStorage.getItem("token")
    customerInfos.lang = "en"

    const params = new URLSearchParams();

    if(anonymToken != null){
        params.append("anonymToken", anonymToken)
    }

    // TODO RQU : TEMP REMOVE MODAL IDENTIFICATION
    // params.append("email", customerInfos.email)
    // params.append("firstName", customerInfos.firstName)
    // params.append("lastName", customerInfos.lastName)
    // params.append("lang", customerInfos.lang)

    axios.post(CUSTOMER_URL.REGISTER_CUSTOMER_ANONYM() , params)
        .then( response => {
            if(response.status == 200){
                console.log(response.data)
                if(!response.data.error){
                    localStorage.setItem("IEC_FIRSTNAME", response.data.firstName)
                    localStorage.setItem("IEC_TOKEN", response.data.anonymToken)
                    localStorage.setItem("IEC_LASTNAME", response.data.lastName)
                    // localStorage.setItem("IEC_EMAIL", customerInfos.email)
                    localStorage.setItem("IEC_COMPLEX_ID", response.data.complexId)

                    // if(customerInfos.smartShelvesId != null){
                    //     localStorage.setItem("smartShelvesId", response.data.firstName)
                    // }
                }

                callback(response.data);
            }
        });

}

export function updateCustomer(parameters){
    var path = "/customer/update";
    var url = app.DKW_URL + path;
    var error = true;

    var request = new XMLHttpRequest();
    var params = "params=" + JSON.stringify(parameters)
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    app.appContext.showAlert(app.appContext.$t('errorUpdateCustomer'),"error");
                }else{
                    app.appContext.showAlert(app.appContext.$t('successUpdateCustomer'),"success");
                }
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);

    return error;
}

export function updateCustomerEmail(email, callback){
    var path = "/customer/update/email";
    var url = app.DKW_URL + path;
    var error = false;

    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "token=" + token + "&email=" + email;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    error = true;
                    app.appContext.showAlert(app.appContext.$t('errorUpdateEmail'),"error");
                }
                callback(error)
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}


export function updateCustomerLoyaltyCard(loyaltyCardNumber){
    var path = "/customer/update/loyalty/card";
    var url = app.DKW_URL + path;
    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "token=" + token + "&cardNumber=" + loyaltyCardNumber;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    app.appContext.showAlert(app.appContext.$t('errorUpdateLoyaltyCard'),"error");
                }
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}


export function updateCustomerLoyaltyPoints(newNbPoints){
    var path = "/customer/update/loyalty/points";
    var url = app.DKW_URL + path;
    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "token=" + token + "&nbPoints=" + newNbPoints;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if (response.error == true) {
                    app.appContext.showAlert(app.appContext.$t('errorUpdateLoyaltyPoints'),"error");
                }
            }
        }
    };
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function getCustomer(callback){
    var path = "/customer/getcustomer/token";
    var url = app.DKW_URL + path;
    var token = "";
    var univers = localStorage.getItem("DKC_univers");
    switch(univers){
        case "1":
            token = localStorage.getItem("DKC_fashion_token");
            break;
        case "2":
            token = localStorage.getItem("DKC_food_token");
            break;
        case "3":
            token = localStorage.getItem("DKC_fuel_token");
            break;
    }

    var request = new XMLHttpRequest();
    var params = "?token=" + token;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200 && request.response != "") {
                callback(request.response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetCustomer'),"error");
            }
        }
    };
    request.open('GET', url + params, false);
    request.send();
}



export function getAnonymToken(callback){
    var url = CUSTOMER_URL.REGISTER_CUSTOMER_ANONYM();

    var request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200 && request.response != "") {
                if(!request.response.error){
                    localStorage.setItem("IEC_TOKEN", JSON.parse(request.response).anonymToken)
                }
                callback(request.response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetCustomer'),"error");
            }
        }
    };
    request.open('POST', url, false);
    request.send();
}
