var axios = require("axios")
import { LOYALTY_URL } from "@/assets/js/constantUtils"

export function updateLoyalty(jsonInfos, callback) {
    const params = new URLSearchParams();
    params.append("jsonInfos", JSON.stringify(jsonInfos))

    axios.post(LOYALTY_URL.UPDATE_LOYALTY_INFOS(), params)
        .then( response => {
            if(response.status == 200){
                if(!response.data.error){
                    callback(null);
                }else{
                    callback(response.data);
                }
            }
        });

}
