let video = null
export function init(dest, constraints, successCallback, errorCallback, onResizeCallBack){

    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
        let videoCanvas = document.createElement("canvas");
        let ctx = videoCanvas.getContext("2d");
        let ratio = 0
        navigator.mediaDevices.getUserMedia(constraints)
            .then( stream => {
                video =  document.createElement("video")
                video.srcObject = stream
                video.setAttribute("mute", '')
                video.setAttribute("muted", '')
                video.setAttribute("playsinline", '')
                video.setAttribute("autoplay", '')
                video.setAttribute("loop", true)
                video.setAttribute("style", "opacity: 0; position:absolute;z-index:-99")
                video.onloadedmetadata = function() {
                    ratio = this.videoHeight / this.videoWidth
                    ctx.canvas.width = dest.clientWidth
                    ctx.canvas.height = ctx.canvas.width * ratio
                    dest.appendChild(video);
                    dest.appendChild(videoCanvas);
                    successCallback({width: this.videoWidth, height: this.videoHeight}, video)
                }

                video.addEventListener("play", function() {
                    (function loop() {
                        if(!video.paused && !video.ended){
                            let imageWidth =  ctx.canvas.width
                            let imageHeight = ctx.canvas.width * ratio
                            ctx.drawImage(video, 0, 0, imageWidth, imageHeight)
                            window.requestAnimationFrame(loop)
                        }
                    })()
                })
            })
            .catch(e => {
                console.error(e)
                alert(`Error while initiating Webcam: ${e}`)
                errorCallback()
            })
        window.onresize = () => {
            ctx.canvas.width = window.innerWidth
            ctx.canvas.height = ctx.canvas.width * ratio
            onResizeCallBack()
        }
    }
}

export function stop(){
    if(video !== null){
        video.srcObject.getTracks().forEach(track => track.stop())
    }
}

export function pause(){
    if(video !== null){
        video.pause()
    }
}

export function play(){
    if(video !== null){
        video.play()
    }
}



