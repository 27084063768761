import {urltoFile} from "./Utils";
import {FACE_RECOGNITION_URL} from "@/assets/js/constantUtils";
var axios = require("axios")

export async function getSignatureFromBase64(base64){
    let file = await urltoFile(base64, "face.jpg", "image/jpeg")

    const params = new FormData();

    params.append("user_id", localStorage.getItem("IEC_FIRSTNAME") + "." + localStorage.getItem("IEC_LASTNAME") + "." + localStorage.getItem("IEC_COMPLEX_ID"));
    params.append("image", file, file.name)


    return axios.post(FACE_RECOGNITION_URL.GET_SIGNATURE() , params, {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarylGiwSjpTBFFTKHef'
        }
    })

}

export function addSignatureToCorpus(signature){
    const params = new FormData();
    let corpus = localStorage.getItem("IEC_corpus") || "Grocery"
    params.append("user_id", localStorage.getItem("IEC_FIRSTNAME") + "." + localStorage.getItem("IEC_LASTNAME") + "." + localStorage.getItem("IEC_COMPLEX_ID"));
    params.append("signature", signature)
    params.append("corpus_id", corpus)


    return axios.post(FACE_RECOGNITION_URL.ADD_SIGNATURE() , params)

}
