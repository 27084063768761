<template>
    <div :class="{'product-bloc productIsDeleted': true, 'fixed-width': inCarousel}">
        <div :class="{'product-wrapper': true, 'main-color-border': withBorder,'full-width': fullWidth, 'show-in-column': inCarousel}">
            <div :class="{'productIsDeleted': true, 'opacity': productIsDeleted}" class="product-img" :style="{backgroundImage: `url('${productImgUrl}')`}"></div>
            <div v-if="productIsDeleted">
                <div class="product-infos">
                    <span :class="{'productIsDeleted': true, 'opacity': productIsDeleted}" class="product-name">{{translate(product.name)}}</span>
                    <span v-show="false" class="product-old-price">{{formattedPrice(product.price * 1.5)}}€</span>
                </div>
                <template>
                    <span class="product-more-infos delete-text">{{$t("deleteFoodAlert")}}</span>
                </template>
            </div>
            <div class="product-infos-wrapper" v-else>
                <div class="product-infos">
                    <span class="product-name">{{translate(product.name)}}</span>
                    <span v-show="false" class="product-old-price">{{formattedPrice(product.price * 1.5)}}€</span>
                </div>
                <div class="product-more-infos" :style="`flex-direction: ${nutriscore != null ? 'row-reverse' : 'row'} `">
                    <img class="product-nutriscore" v-if="nutriscore" :src="require(`@/assets/img/nutriscore/${nutriscore}.png`)" :alt="`Nutriscore : ${nutriscore.toUpperCase()}`">
                    <div v-if="inCrossSell">
                        <span class="product-price">{{formattedPrice(product.price)}}€</span>
                    </div>
                    <div class="d-flex flex-column" v-else>
                        <span class="product-price">{{formattedPrice(product.price*qty)}}€</span>
                        <span class="product-price-unit"><small>{{formattedPrice(product.price)}}€/{{$t("unit")}}</small></span>
                    </div>
                </div>
                <div v-if="showMore" class="reviews">
                    <div v-b-toggle="`collapse-${product.id}-inner`" @click="showMoreHandler()" size="sm">
                        <span v-if="showMoreInfos" class="more-info">{{$t("moreInfo")}}</span>
                    </div>
                </div>
            </div>
            <div :class="{'product-qty-wrapper': true, 'show-in-row': inCarousel, 'reverse-row': inCarousel}">
                    
                <template v-if="inCarousel && qty == 0">
                    <span class="product-add-new" v-if="!hideQty" @click="incQty"><span class="mr-2 icon-panier"></span>Add to basket</span>
                </template>
                <template v-else-if="productIsDeleted"></template>
                <template v-else>
                    <span :class="{'icon-plus icon product-plus-icon': true} " v-if="!hideQty" @click="incQty"></span>
                    <span :class="{'product-qty': true, 'mx-3': inCarousel}">{{qty}}</span>
                    <span class="icon-moins icon product-minus-icon" v-if="!hideQty" @click="decQty"></span>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import {getProductQuantity, addProductToLocalBasket, decreaseProductFromLocalBasket} from "@/assets/js/Food/Basket"
import {getCrossSellProducts} from "@/assets/js/SearchProduct"
import {nutriscoreMixin} from "@/mixins/nutriscoreMixin"
import {getConfig} from "@/assets/js/Utils"
export default {
    name: "ProductBloc", 
    mixins: [nutriscoreMixin],
    props: {
        product: Object,
        withBorder: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        hideQty:{
            type: Boolean,
            default: false
        },
        forcedQty:{
            type: Number
        },
        inScan:{
            type: Boolean,
            default: false
        },
        showMore:{
            type: Boolean,
            default: false
        },
        inCrossSell:{
            type: Boolean,
            default: false
        },
        inCarousel:{
            type: Boolean,
            default: false
        },
        productIsDeleted:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            qty: this.getQty(),
            showMoreModal: false,
            nutriscore: null,
            ingredients: null,
            allergens: null,
            nutriments: null,
            totalAmount: this.getTotalAmount(),
            showMoreInfos: false
        }
    },
    methods:{
        incQty(){
            sessionStorage.setItem("DKC_food_suspect_basket", true);
            addProductToLocalBasket(this.product)
            this.qty = this.getQty()
            this.$emit("updateQty")
        },
        decQty(){
            sessionStorage.setItem("DKC_food_suspect_basket", true);
            decreaseProductFromLocalBasket(this.product)
            this.qty = this.getQty()
            this.$emit("updateQty")
        }, 
        formattedPrice(price){
            return price.toFixed(2).toString().replace(".", ",")
        },
        getQty(){
            if(this.forcedQty){
                return this.forcedQty;
            }else{
                return getProductQuantity(this.product)
            }
        },
        showMoreHandler(){
            let infoToEmit = {
                inScan: this.inScan,
                product: this.product
            }
            this.$root.$emit('showMore', infoToEmit)
            this.showMoreModal = true
        },
        isEmpty(obj){
            return obj && Object.keys(obj).length === 0
        },
        formatDesc(){
            let description = null
            let desc = this.translate(this.product.description)
            if(desc){
                description = {}
                let splitDescription = desc.split("--end--")
                splitDescription.forEach(desc => {
                    let subDesc = {}
                    let titleMatches = desc.match(/\{{(.*?)\}}/);
                    if(titleMatches){
                        let splitSubDesc = desc.split("}\n")[1].split("\n")
                        splitSubDesc.forEach(sub => {
                            if(sub.split(":")[1]){
                                subDesc[sub.split(":")[0].split("?").join("")] = sub.split(":")[1].split("?").join("")
                            }
                        })
                        description[titleMatches[1]] = subDesc
                    }
                })
            }
            return description
        },
        getTotalAmount(){  
            this.qty = this.getQty()
            let pPrice = this.product
            return pPrice.price*this.qty
        }
    },
    created(){
        this.$root.$on("updateQty", () => {
            this.qty = this.getQty()
        })
        let formattedDescription = this.formatDesc()
        this.fetchData(this.product.ean).then(() => {
            this.nutriscore = this.getNutriscore()
            let ingredients = this.getIngredients()
            let allergens = this.getAllergens()
            let nutriments = this.getNutrimentsFor100g()
            getCrossSellProducts(this.product.id, products => {
                let crossSellProducts = products
                if(ingredients || allergens || nutriments || crossSellProducts.length > 0){
                    this.showMoreInfos = true
                }else{
                    this.showMoreInfos = false
                }
            })
        })
    },
    computed:{
        productImgUrl(){
            return `${getConfig().url_img}/${this.product.image}`
        },
        formattedDescription(){
            return this.formatDesc()
        },
    },
    watch:{
        nutriscore(){
            this.showMoreModal = this.nutriscore != null
        }
    }
}

</script>
<style scoped>
    .more-info{
        text-decoration: underline;
        font-style: italic;
        font-size: .85rem;
        color: rgb(var(--hot-food-color));
    }
    .additional-info{
        font-size: .9rem;
    }
    .additional-info p{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .additional-info.in-scan{
        max-height: 150px;
        overflow: scroll;
    }
    .acc-header {
        background: rgb(var(--hot-food-color));
        margin: 2px 0;
        border-radius: 12px;
        color: white;
        font-weight: bold;
        padding: 8px;
    }
    .product-bloc {
        position: relative;
        z-index: 10;
    }
    .desc-line{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .nutriments-table{
        width: 100%;
    }
    .nutriments-table .nutriments-table-head{
        background: rgb(var(--hot-food-color)) !important;
        font-weight: bold;
        color: white;
    }
    .nutriments-table .nutriments-table-body{
        border: 1px solid;
        border-top: none;
    }
    .nutriments-table .value{
        text-align: center;
        border-left: 1px solid;
    }
    .nutriments-table td{
        padding: 4px;
    }
    .additional-info-modal-footer{
        width: 100%;
        justify-content: center;
        display: flex;
    }
    .product-add-new{
        background: #5cc1b1;
        padding: 8px 16px;
        color: white;
        border-radius: 50px;
    }
    .reverse-row{
        flex-direction: row-reverse !important;
    }
    .opacity{
        opacity: 0.5; 
    }
    .delete-text{
        font-size: 15px;
        color: black;
        border-radius: 30px;
        padding: 10px;
        justify-content: center;
        text-align: center;
    }
    .product-price-unit{
        justify-content: start;
        font-size: 0.8rem;
        font-weight: bold;
        color: rgba(var(--hot-food-color),1);
    }
</style>